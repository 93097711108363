import { useRegenerationPhotos } from "shared/Hooks/regeneration/useRegenerationPhotos";

export const useRegenerationData = () => {
  const photos = useRegenerationPhotos();

  return {
    metaInfo: {
      title: "Regeneracja narzędzi",
      ogDescription:
        "Powierz nam swój zużyty zestaw narzędziowy i zaoszczędź nawet 70% w stosunku do zakupu nowych narzędzi. Zajmujemy się profesjonalną regeneracją frezów, wierteł i innych narzędzi skrawających.",
    },
    hero: {
      title: "Regeneracja narzędzi",
      subtitle:
        "Powierz nam swój zużyty zestaw narzędziowy i zaoszczędź nawet 70% w stosunku do zakupu nowych narzędzi. Zajmujemy się profesjonalną regeneracją frezów, wierteł i innych narzędzi skrawających.",
      bg: photos.hero,
    },
    content: {
      title: "Przywracamy sprawność narzędzi",
      subtitle:
        "Nasza usługa regeneracji narzędzi pozwala na odtworzenie oryginalnej geometrii i powłoki. Dzięki regeneracji, stare zużyte narzędzia odzyskują swoją sprawność, co pozwala na ich wydajne wykorzystanie w ponownym cyklu życia. Usługa regeneracji jest zdecydowanie bardziej ekonomicznym rozwiązaniem od zakupu nowego zestawu narzędzi. Zapraszamy do skorzystania z naszych usług. Aby dowiedzieć się więcej, prosimy o kontakt telefoniczny.",
      src: photos.img1,
      bigImg: true,
    },
  };
};
