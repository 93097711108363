import React from "react";
import Regeneration from "sections/Regeneration/Regeneration";
import { useRegenerationData } from "lang/regeneration";

const RegenerationPage = () => {
  const data = useRegenerationData();
  return <Regeneration data={data} />;
};

export default RegenerationPage;
